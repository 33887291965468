<template>
  <home-tpl>
    <b-card
      title=""
      class=" mx-auto"
      text-variant="left"
      align-v="center">    
    Assunto: {{dados.assunto}} <br>
    Contatos: <span v-for="item in dados.consulta" :key="item.nome">{{item.nome}}</span><br>
    Conteúdo do E-mail:

    <div v-html="dados.conteudo"></div>

    <b-button variant="success" v-on:click="enviar()">Enviar E-mail</b-button>
    <b-button variant="dark" href="#">Editar</b-button>
    </b-card>

  </home-tpl>
</template>

<script>
// @ is an alias to /src
import HomeTpl from '@/tpls/HomeTpl.vue'

export default {
  name: 'Ver',
  data() {
    return {
      dados: false,
      usuario: false,
    }
  },


  components: {
    HomeTpl,
  },

  created(){
  
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'newsletter/ver/'+this.$route.params.id,
      {"headers": {"authorization": "Bearer "+this.$store.getters.getToken}})
        .then(response => {
          //
          if(response.data.status){
            
            //console.log(response.data.dados);
            this.dados = response.data.dados;
          }
        })
          
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })  

      }
    },  
  
  methods:{

    enviar(){

      this.$http.get(this.$urlApi+'newsletter/enviaremail/'+this.$route.params.id, 
      //this.$http.post(this.$urlApi+'enviando/', 
      {"headers":{"authorization":"Bearer "+this.$store.getters.getToken}})      
      
      .then(response => {
          if(response.data.status){

            //alert("enviado");
            console.log(response.data.dados);

          // this.$router.push('/newsletter/ver/'+response.data.newsletter.id);

          }else{

            alert('Erro no envio! Tente novamente mais tarde.');

          }
        })
        
        .catch(e => {
          console.log(e)
          alert("Erro! Tente novamente mais tarde!");
        }) 

    },


  }

}
</script>

